import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.scss']
})
export class OurStoryComponent implements OnInit {
  dynamicNavOptions: { name: string, link: string }[] = [
    { name: 'Blog', link: '' },
    { name: 'Solex University', link: '' },
    { name: 'Events', link: '' }
];
  constructor() { }

  ngOnInit(): void {
  }

}
