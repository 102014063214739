import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-header',
  templateUrl: './navigation-header.component.html',
  styleUrls: ['./navigation-header.component.scss']
})
export class NavigationHeaderComponent implements OnInit {
  @Input() navOptions: { name: string, link: string }[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
