import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ao-scan',
  templateUrl: './ao-scan.component.html',
  styleUrls: ['./ao-scan.component.scss']
})
export class AOSCANComponent implements OnInit {
  dynamicNavOptions: { name: string, link: string }[] = [
    { name: 'Quick Scan', link: '' },
    { name: 'EZ Scan', link: '' },
    { name: 'Inner Voice', link: '' },
    { name: 'Vitals', link: '' },
    { name: 'Body Systems', link: '' },
    { name: 'Comprehensive', link: '' },
    { name: 'MultiScan', link: '' },
    { name: 'Homeo-Energetix', link: '' },
    { name: 'Dental/TMJ', link: '' },
    { name: 'S.E.F.I.', link: '' },
    { name: 'AO MindSync', link: '' },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
